tr {
  cursor: pointer;
}

tr.rowSelected td {
  background-color: #eff6ff !important;
}

th {
  /* background-color: #99c5fa !important; */
  /* background-color: #f1f5f9 !important; */
  /* background-color: #1e293b !important; */
  background-color: #0f172a !important;
  color: #fff !important;
}

td {
  border-bottom-color: #b0d2ff !important;
}

.lastSelected {
  color: #1625ee;
  color: #dd00ff;
  font-weight: 500;
}


.ant-table.ant-table-small th {
  padding: 4px 8px !important;
}

.ant-table.ant-table-small td {
  padding: 4px 8px !important;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px;
}

.ant-drawer-mask {
  background-color: rgba(0, 39, 102, 0.3);
}

element.style {
  row-gap: 0px;
}

.ant-form-vertical .ant-form-item {
  flex-direction: column;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-form-item-with-help {
  margin-bottom: 0;
  transition: none;
}

.ant-form-item-with-help .ant-form-item-explain {
  height: 35px;
}

.ant-form-item-label {
  padding-bottom: 4px !important;
}

.ant-drawer-header {
  background-color: #6ba5d7;
  border-bottom: 1px solid #6ba5d7;
  min-height: 60px;
}

.ant-drawer-title {
  font-size: 20px;
}

.ant-badge-count-sm {
  min-width: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 13px !important;
  border-radius: 7px;
}

.ant-badge-multiple-words {
  padding: 0 3px 0 6px !important;
}


.inner-drawer-body {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 137px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}


.drawer-footer {
  padding: 10px 0px;
}


.inner-drawer-buttons {
  display: flex !important;
  justify-content: flex-end;
  margin: 0 -24px;
  padding: 10px 24px;
}

/* .ant-select-disabled, .ant-input-disabled {
    color: rgba(0,0,0,0.85) !important;
  } */

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.5);
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.5);
}

.myinputnumber > .ant-input-number-input-wrap > .ant-input-number-input {
  font-weight: 700;
}

.grid-layout1 {
  height: 100vh;
  display: grid;
  grid-template-columns: 144px 1fr;
  grid-template-rows: 60px 30px 48px calc(100vh - 210px) 40px 32px;
  grid-template-areas:
    "logo header"
    "sidenav titulo "
    "sidenav titulo "
    "sidenav content "
    "sidenav buttons "
    "footer footer "
  ;
}

.grid-layout2 {
  height: 100vh;
  display: grid;
  grid-template-columns: 0px 1fr;
  grid-template-rows: 60px 30px 48px calc(100vh - 210px) 40px 32px;
  grid-template-areas:
    "logo header"
    "sidenav titulo "
    "sidenav titulo "
    "sidenav content "
    "sidenav buttons "
    "footer footer "
  ;
}

.class-menu-lateral1 {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding: 16px 0 16px 16px; */
  background-color: #293c61;
  /* border-right: 1px solid red; */
  /* border-right: 1px solid white; */
}

.class-menu-lateral2 {
  grid-area: sidenav;
  display: none;
  flex-direction: column;
  /* justify-content: center; */
  /* padding: 16px 0 16px 16px; */
  background-color: #293c61;
  /* border-right: 1px solid red; */
  /* border-right: 1px solid white; */
}


.pageLogo {
  grid-area: logo;
  background-color: #293c61;
  /* border-right: 1px solid red; */
}

.pageHeader {
  grid-area: header;
  display: flex;
  display: flex;
  align-items: center;
  padding: 0 20px;
  /* padding: 0 164px; */
  background-color: blue;
  /* background-color: #293c61; */
  color: white;
}

.pageTitulo {
  grid-area: titulo;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  padding: 5px 20px;
  /* background-color: aqua; */
  /* background-color: #f9f9f9; */
}

.pageContent {
  grid-area: content;
  display: flex;
  flex-direction: column;
  /* min-width: 360px; */
  /* padding: 16px 0 16px 0px; */
  /* padding: 16px 0 0 0; */
  /* padding-top: 16px; */
  height: calc(100vh - 210px);
  /* overflow-x: hidden; */
  overflow-y: auto;
  /* background-color: #f9f9f9; */
}

/* .contentData {
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 210px);
  overflow-x: auto;
} */

.pageButtons {
  grid-area: buttons;
  display: flex;
  height: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  /* background-color: #506ba1; */
}

.pageFooter {
  grid-area: footer;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: blueviolet;
}
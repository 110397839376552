/* .animation-duration-1000 {
    animation-duration: 1000ms !important;
} */
.fadein {
    animation: fadein 0.50s linear !important;
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
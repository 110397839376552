/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.css';
@import './styles/grid.css';
@import './styles/overrideAnt.css';
@import './styles/spinner.css';
@import './styles/animation.css';
@import  "react-datepicker/dist/react-datepicker.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

input {
  outline: none;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
} */

/* remover up/down arrows de input number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* input.input-number::-webkit-inner-spin-button, 
input.input-number::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} */

/* scroll bar personalizada */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  /* border-radius: 4px; */
  /* background-color: #151535; */
  /* background-color: #f8f9fb; */
  background-color: #f8f9fb;
}

::-webkit-scrollbar-thumb {
  background-color: #c8c9cb;
  /* border-radius: 4px; */
}

::-webkit-scrollbar-thumb:hover {
  /* background-color: #ff0044; */
  background-color: #88898b;
}

/* Cor de fundo do autocomplete */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

/* Cor do texto do autocomplete */
input:-webkit-autofill {
  -webkit-text-fill-color: blue !important;
}

.my-pl-td-1 { padding-left: 10px;}
.my-pl-td-2 { padding-left: 20px;}
.my-pl-td-3 { padding-left: 30px;}
.my-pl-td-4 { padding-left: 40px;}
.my-pl-td-5 { padding-left: 50px;}